export function checkSpaceMemberDirectAccess(community, member) {
  //console.log('community.accessibility', community.accessibility);
  if (community.accessibility === 3 && member == null) {
    return true;
  }
  return false;
}

export function checkCommunityDates(community) {
  const now = new Date().getTime();
  const openingDate = community.openingDate != null ? new Date(community.openingDate) : null;
  const closingDate = community.openingDate != null ? new Date(community.closingDate) : null;
  if (
    (openingDate != null && openingDate > now)
    || (closingDate != null && closingDate < now)
    || community.inMaintenance
  ) {
    return false;
  }
  return true;
}
export function checkDates(startDate, endDate) {
  const now = new Date().getTime();
  const openingDate = startDate != null ? new Date(startDate) : null;
  const closingDate = endDate != null ? new Date(endDate) : null;
  if (
    (openingDate != null
      && openingDate < now
      && closingDate != null
      && closingDate > now)
    || (openingDate != null && closingDate == null && openingDate < now)
    || (closingDate != null && openingDate == null && closingDate > now)
    || (openingDate == null && closingDate == null)
  ) {
    return true;
  }
  return false;
}
export function checkCommunityDatesNew(community) {
  const now = new Date().getTime();
  const openingDate = community.openingDate != null ? new Date(community.openingDate) : null;
  const closingDate = community.closingDate != null ? new Date(community.closingDate) : null;
  if (
    (openingDate != null
      && openingDate < now
      && closingDate != null
      && closingDate > now)
    || (openingDate != null && closingDate == null && openingDate < now)
    || (closingDate != null && openingDate == null && closingDate > now)
    || community.inMaintenance
    || (openingDate == null && closingDate == null)
  ) {
    return true;
  }
  return false;
}

export function checkCommunityStartAndEnd(community) {
  const now = new Date().getTime();
  if (
    (community.startDate != null && community.startDate > now)
    || (community.closingDate != null && community.closingDate < now)
  ) {
    return false;
  }
  return true;
}
export function checkCommunityRegisterStartAndEnd(community) {
  const now = new Date().getTime();
  const registrationStartDate = community.registrationStartDate != null
    ? new Date(community.registrationStartDate)
    : null;
  const registrationEndDate = community.registrationEndDate != null
    ? new Date(community.registrationEndDate)
    : null;
  if (
    (registrationStartDate != null
      && registrationStartDate < now
      && registrationEndDate != null
      && registrationEndDate > now)
    || (registrationStartDate != null
      && registrationEndDate == null
      && registrationStartDate < now)
    || (registrationEndDate != null
      && registrationStartDate == null
      && registrationEndDate > now)
    || (registrationStartDate == null && registrationEndDate == null)
  ) {
    return true;
  }
  return false;
}
/* export function checkCommunityRegisterStartAndEnd(community) {
  const now = new Date().getTime();
  if (
    (community.registrationStartDate != null &&
      community.registrationStartDate > now) ||
    (community.registrationEndDate != null &&
      community.registrationEndDate < now)
  ) {
    return false;
  }
  return true;
} */

export function checkOnboarding(currentSpace, loggedMember) {
  console.log('checkOnboarding...');

  console.log('currentSpace:', currentSpace);
  console.log('loggedMember:', loggedMember);

  if (currentSpace.hasForm && !loggedMember.completedForm && !loggedMember.bypassForm) {
    return "form";
  }
  if (currentSpace.hasPrice && !loggedMember.isSubscribed && !loggedMember.bypassPlans) {
    return "payments";
  }
  if (
    (currentSpace.mainType === "Event" && !currentSpace.bypass && currentSpace.parentKey)
    || (currentSpace.bypass && currentSpace?.modality === "hybrid" && !loggedMember.modality)
  ) {
    return "welcome";
  } 
  return null;
}

export function checkOnboardingWithoutMemberParams(community) {
  console.log('checkOnboardingWithoutMemberParams...');
  if (community.hasForm) {
    return "form";
  }
  if (community.hasPrice) {
    return "payments";
  }
  return null;
}

export function checkOccupation(community, buttons, traduc) {
  if (
    buttons.Online
    && community.occupation.online <= community.onlineOccupation
  ) {
    buttons.Online = {
      text: traduc.textClosedOnline || traduc.generalClosed,
      action: "closed",
      variant: "outline-primary",
    };
  }

  if (
    buttons.Onsite
    && community.occupation["on-site"] <= community.onsiteOccupation
  ) {
    buttons.Onsite = {
      text: traduc.textClosedOnsite || traduc.generalClosed,
      action: "closed",
      variant: "outline-primary",
    };
  }

  return buttons;
}
