// import Bugsnag from '@bugsnag/js';
import router from '@/router';
import $services from '@/config/container';
import Service from '@/config/service-identifiers';
import Vue from 'vue';
import { selectLangFromCommunity } from '@/@core/utils/collective';
import store from '..';
import {
  checkSpaceMemberDirectAccess,
  checkOnboarding,
  checkCommunityDatesNew,
} from './store-helpers';
import { getDomain } from '../auth/auth.store';

export default {
  state: {
    maincollective: {},
    collective: {},
    parentcollective: {},
    communitySlug: '',
    owner: {},
    communityCustomizations: {},
    communityError: '',
    communityinitialized: false
  },
  getters: {
    currentCollective(state) {
      // Get communitySlug from URL when doesnt exist a community route
      state.communitySlug = window.location.pathname.split('/')[1];

      // Get data from collective and set it

      return state.collective;
    },
    mainCollective(state) {
      return state.maincollective;
    },
    parentCollective(state) {
      return state.parentcollective;
    },
    collectiveFavicon(state) {

      return (
        (state.maincollective?.header?.logo_url
          || state.maincollective?.logoURL)
        ?? (state.collective?.header?.logo_url || state.collective?.logoURL)
      );
    },
    owner(state) {
      return state.owner;
    },
    communityCustomizations(state) {
      return state.communityCustomizations;
    },
    communityError(state) {
      return state.communityError;
    },
    communityinitialized(state) {
      return state.communityinitialized
    }
  },
  mutations: {
    setCurrentcommunitySlug(state, communitySlug) {
      state.communitySlug = communitySlug;
    },
    setCurrentMainCollective(state, maincollective) {
      const main = state.collective.key !== maincollective.key ? maincollective : null;
      state.maincollective = main;
      return maincollective;
    },
    setCurrentCollective(state, collective) {
      state.collective = collective;
    },
    setCurrentParentCollective(state, parent) {
      state.parentcollective = parent;
    },
    setOwner(state, owner) {
      state.owner = owner;
    },
    setCustomization(state, customization) {
      state.communityCustomizations = customization;
    },
    setCommunityInitialized(state, spaceSlug) {
      state.communityinitialized = spaceSlug;
    },
    setBackofficeInitialized(state, spaceSlug) {
      state.backofficeinitialized = spaceSlug;
    },
    setError(state, communityError) {
      state.communityError = communityError;
    },
  },
  actions: {
    async getCommunity({
      dispatch, commit, getters, rootGetters, state,
    }) {
      console.log('Getting space...');

      if (
        this.getters.currentCollective.slug
        === window.location.pathname.split('/')[1]
      ) {
        return;
      }

      try {
        const response = await $services[Service.BackendClient].get(
          'communities',
          {
            params: {
              communitySlug: window.location.pathname.split('/')[1],
              isInside: true,
              maxParentRecursivity: 1,
              count: 1,
            },
          },
        );
        //console.log('getCommunity response', response);
        let responseData = response.data;
        if(responseData.data){
          responseData = responseData.data;
        }
        if(responseData[0]){
          responseData = responseData[0];
        }
        //console.log('getCommunity responseData', responseData);
        commit('setCurrentCollective', responseData);
        if (responseData?.slug && responseData?.hasSubscription === false && responseData?.parentKey === null) {
          //Redirect to view to select a plan
          router.push({ 
            name: 'my-plans', 
            params: { 
              community: responseData.slug 
            } 
          });
          return;
        }
      } catch (error) {
        //console.log('getCommunity response error', error);
        state.collective = error.response?.data;
        commit('setCurrentCollective', error.response?.data);
        return router.push({ name: 'error', params: { error: '404' } });
      }
    },
    async getMainCommunity({
      dispatch, commit, getters, rootGetters, state,
    }) {
      if (state.collective.communityMain == null) {
        return;
      }

      if (state.collective.communityMain === state.collective.key) {
        return;
      }

      try {
        const response = await $services[Service.BackendClient].get(
          'communities',
          {
            params: {
              communityKey: state.collective.communityMain,
              isInside: true,
              maxParentRecursivity: 1,
            },
          },
        );
        commit('setCurrentMainCollective', response.data.data[0]);
      } catch (error) {
        console.log('error', error);
        if(error?.response?.data){
          commit('setCurrentMainCollective', error.response.data);
        }
      }
    },
    async getParentCommunity({
      dispatch,
      commit,
      getters,
      rootGetters,
      state,
    }) {
      if (state.collective.parentKey == null) {
        return;
      }

      try {
        const response = await $services[Service.BackendClient].get(
          'communities',
          {
            params: {
              communityKey: state.collective.parentKey,
              isInside: true,
              maxParentRecursivity: 1,
            },
          },
        );
        commit('setCurrentParentCollective', response?.data?.data[0]);
      } catch (e) {
        commit('setCurrentParentCollective', e.response?.data);
      }
    },
    async getSpaces({
      dispatch, commit, getters, rootGetters, state,
    }) {
      if (
        this.getters.currentSpace.slug
        === window.location.pathname.split('/')[1]
      ) {
        return;
      }

      try {
        const response = await $services[Service.BackendClient].get(
          'spaces',
          {
            params: {
              slug: window.location.pathname.split('/')[1]
            },
          },
        );
        commit('setCurrentSpace', response.data.data[0]);
      } catch (error) {
        state.space = error.response.data;
        commit('setCurrentSpace', error.response.data);
      }
    },
    async getSpace({
      dispatch, commit, getters, rootGetters, state,
    }) {
      if (
        this.getters.currentSpace.slug
        === window.location.pathname.split('/')[1]
      ) {
        return;
      }

      try {
        const response = await $services[Service.BackendClient].get(
          'space',
          {
            params: {
              slug: window.location.pathname.split('/')[1]
            },
          },
        );
        commit('setCurrentSpace', response.data.data[0]);
      } 
      catch (error) {
        state.space = error.response.data;
        commit('setCurrentSpace', error.response.data);
      }
    },

    /*
      Función que inicializa los datos de una comunidad  y lleva la logica de redirecciones.
      Primero conseguira la comunidad, comunidad padre y comunidad main.
      Después comprueba si existe la comunidad. En caso de que no exista lleva a la pagina de 404.
      Después comprobara si la comunidad es de accesso 3 y el usuario que solicita la comunidad no es miembro. En caso de que no sea miembro le deja pasar.
      Después comprobara si el usuario no es staff. Si no es staff comprobara lo siguiente:
        - openingDate y closingDate
        - Maintenance
      Después si el status del member es 8 o 81. En caso de que lo sea revisará
        - Tiene onboarding form y no lo ha completado
        - Tiene payments y no los ha completado
    */

    async initCurrentCommunity(
      { dispatch, commit, getters, rootGetters, state, rootState },
      { communitySlug, force = false },
    ) {
      console.log('Initializing space...');

      commit('setCommunityInitialized', null);
      //console.log('Enter in initCurrentCommunity function with communitySlug:', communitySlug);
      if (
        getters.currentCollective
        && getters.currentCollective.slug === communitySlug
        && !force
      ) {
        //console.log('getters.currentCollective && getters.currentCollective.slug === communitySlug && !force');
        return;
      }

      //console.log('Getters:', getters);

      if (!communitySlug) {
        return;
      }

      // Primero conseguira la comunidad
      //console.log('communitySlug', communitySlug);
      //console.log('getters.currentCollective?.slug', getters.currentCollective?.slug);
      const isCurrentCollectiveInStore = communitySlug === getters.currentCollective?.slug;
      //console.log('isCurrentCollectiveInStore', isCurrentCollectiveInStore);

      if(!getters.currentCollective?.slug || !isCurrentCollectiveInStore){
        await dispatch('getCommunity');               // --> getters.currentCollective
      }
      const currentSpace = getters.currentCollective;
      // console.log('currentSpace after dispatch getCommunity', currentSpace);

      // Después comprueba si existe la comunidad. En caso de que no exista lleva a la pagina de 404.
      if (!currentSpace) {
        return router.push({ 
          name: 'error', 
          params: { 
            error: '404' 
          } 
        });
      }

      //Después obtiene la comunidad main
      if(!getters.mainCollective?.slug){
        await dispatch('getMainCommunity');           // --> getters.mainCollective
      }
      const mainCommunity = getters.mainCollective;

      if(!getters.parentCollective?.slug){
        await dispatch('getParentCommunity');         // --> getters.parentCollective
      }
      const parentCommunity = getters.parentCollective;

      await dispatch('getCommunityCustomizations'); // --> ??

      let inWelcome = false;
      if (window.location.href.toLowerCase().indexOf('welcome') > -1) {
        inWelcome = true;
      }
      
      commit('setCommunityInitialized', currentSpace.slug);
      // console.log('setCommunityInitialized');

      // Después comprobará si la comunidad es de accesso 3 y el usuario que solicita la comunidad no es miembro. 
      // En caso de que no sea miembro le deja pasar.
      const accessDirectly = checkSpaceMemberDirectAccess(currentSpace, rootGetters.loggedMember);
      //console.log('Is it a space with direct access?', accessDirectly);
      if (accessDirectly) {
        //console.log('Direct acces... reload because now the space is already initialized');
        location.reload();
      }

      // 
      if (
        (mainCommunity && typeof (mainCommunity) === 'object' && Object.values(mainCommunity).length > 0)
        && (mainCommunity.canJoin || mainCommunity.status === 8)
        && !currentSpace.bypass
        && !currentSpace.isStaff
        && !inWelcome
        && window.location.href.indexOf(mainCommunity.slug) < 0
      ) {
        console.log('Lets go to the onbiarding form...');
        const main = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
          '{subdomain}',
          mainCommunity.subdomain || 'app',
        ).replace('{slug}', mainCommunity.slug);
        window.location.href = `${main}/onboarding-form`;
        return;
      }

      /*
        Si el usuario no es staff comprobara lo siguiente:
          - openingDate y closingDate
          - Maintenance
      */
      if (!currentSpace.isStaff && !inWelcome && !checkCommunityDatesNew(currentSpace)) {
        console.log('Redirecting to welcome!!');
        // Revisamos si es un espacio
        const spacesTypes = ['Project', 'Event', 'Challenge', 'Course', 'Service', 'Organization', 'Subcommunity'];
        if (spacesTypes.includes(currentSpace.mainType)) {
          window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            '{slug}',
            currentSpace.slug,
          ).replace('{subdomain}', currentSpace.subdomain || 'app')}/welcome`;
        } else {
          router.replace({
            name: 'welcome',
            params: {
              communitySlug: currentSpace.slug,
              communityId: currentSpace.slug,
            },
          });
        }
        return;
      }

      /*
        Después si el status del member es 8. En caso de que lo sea revisará
          - Tiene onboarding form y no lo ha completado
          - Tiene payments y no los ha completado
      */
     const currentSpaceCommunityMemberStatus = currentSpace.status;
      if (currentSpaceCommunityMemberStatus === 7 || currentSpaceCommunityMemberStatus === 8) {
        console.log('Member needs to complete onboarding form or payments');
        console.log('currentSpaceCommunityMemberStatus', currentSpaceCommunityMemberStatus);
        // console.log('rootGetters', rootGetters);

        const onboardingRedirectLogic = checkOnboarding(
          currentSpace,
          rootGetters.loggedMember,
        );
        console.log('onboardingRedirectLogic', onboardingRedirectLogic);

        if(currentSpaceCommunityMemberStatus === 7){
          router.replace({
            name: 'welcome',
            params: {
              communitySlug: currentSpace.slug,
              communityId: currentSpace.slug,
            },
          });
          return;
        }

        //This is for the Member of the main
        if (!rootGetters.loggedMember?.status && !rootGetters.loggedUser?.key) {
          await store.dispatch('fetchLoggedUser', { root: true });
        }

        if (currentSpaceCommunityMemberStatus === 8) {
          
          //WELCOME
          if (onboardingRedirectLogic === 'welcome') {
            if (
              window.location.href.toLowerCase().indexOf('welcome') > -1
            ) {
              return;
            }
            router.replace({
              name: 'welcome',
              params: {
                communitySlug: currentSpace.slug,
                communityId: currentSpace.slug,
              },
            });
            return;
          }

          //ONBOARDING FORM
          if (onboardingRedirectLogic === 'form') {
            if (
              window.location.href.toLowerCase().indexOf('onboarding-form') > -1
            ) {
              return;
            }
            router.replace({
              name: 'onboarding-form',
              params: { communityId: currentSpace.slug },
            });
            return;
          }

          //PAYMENTS
          if (onboardingRedirectLogic === 'payments') {
            router.replace({
              name: 'onboarding-plans',
              params: { communityId: currentSpace.slug },
            });
            return;
          }

          if (onboardingRedirectLogic == null) {
            await dispatch('createItem', {
              item: {
                itemType: 'joinAfterPay',
                requestConfig: {
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
              },
              noSet: true,
            });
            if (Vue.$cookies.get('lastUrl')) {
              const url = Vue.$cookies.get('lastUrl');
              Vue.$cookies.keys().forEach((cookie) => (cookie === 'lastUrl' ? Vue.$cookies.remove(cookie) : ''));
              Vue.$cookies.remove('registeredTo', '/', `.${getDomain()}`);
              Vue.$cookies.remove('registeredTo', '/', getDomain());
              Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));
              window.location.replace(url);
              return;
            }
            //TODO: review
            window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
              '{slug}',
              currentSpace.slug,
            ).replace('{subdomain}', currentSpace.subdomain || 'app')}`; // Esto se ha de eliminar en la proxima versión de FE
            return;
          }
        }

        if (rootGetters.loggedMember == null) {
          if (!inWelcome) {
            if (
              currentSpace.isEvent
              || currentSpace.mainType?.toLowerCase() === 'project'
            ) {
              window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
                '{slug}',
                currentSpace.slug,
              ).replace('{subdomain}', currentSpace.subdomain || 'app')}/welcome`;
              return;
            }
            router.replace({
              name: 'welcome',
              params: {
                communitySlug: currentSpace.slug,
                communityId: currentSpace.slug,
              },
            });
            return;
          }
        }
      }

      if (currentSpace.slug && currentSpace.canJoin == null) {
        // ====================
        //  Todo funciona bien
        // ====================
        selectLangFromCommunity(currentSpace);

        // Compruebo si la comunidad esta bien
        if (parentCommunity?.slug == null && !currentSpace.hasSubscription && !currentSpace.isStaff && currentSpace.createdByMember.key === rootGetters.loggedMember.key) {
          // Le hago un redirect
          router.push({ name: 'my-plans', params: { community: currentSpace.slug } });
          return;
        }

        // Seteamos el valor del GDPR
        if (currentSpaceCommunityMemberStatus === 3) {
          rootState.GDPRAccepted = !!rootGetters.loggedMember?.isGDPRAccepted;
        }

        if (Vue.$cookies.get('registeredTo') != null && Vue.$cookies.get('registeredTo') !== currentSpace.slug) {
          const comCookie = `${Vue.$cookies.get('registeredTo')}`;

          Vue.$cookies.remove('registeredTo', '/', `.${getDomain()}`);
          Vue.$cookies.remove('registeredTo', '/', getDomain());
          Vue.$cookies.remove('registeredTo', '/');
          Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));

          const communityRegisteredTo = (await $services[Service.BackendClient].get(
            'communities',
            {
              params: {
                communitySlug: comCookie,
                isInside: true,
              },
            },
          )).data?.data?.[0];

          if (communityRegisteredTo) {
            try {
              const main = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
                '{subdomain}',
                communityRegisteredTo.subdomain || 'app',
              ).replace('{slug}', communityRegisteredTo.slug);
              window.location.href = `${main}`;
            } catch {
              Vue.$cookies.remove('registeredTo', '/', `.${getDomain()}`);
              Vue.$cookies.remove('registeredTo', `.${getDomain()}`);
              Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));
            }
          }
        }

        if (getters.currentCollective.themeCostumization) {
          if (getters.currentCollective.themeCostumization.primaryColor) {
            document.documentElement.style.setProperty(
              '--primary-color',
              getters.currentCollective.themeCostumization.primaryColor,
            );
            let hsl = getters.currentCollective.themeCostumization.primaryColor;
            hsl = hsl.substring(4, hsl.length - 1);
            hsl = hsl.split(',');
            document.documentElement.style.setProperty(
              '--primary-color-h',
              hsl[0],
            );
            document.documentElement.style.setProperty(
              '--primary-color-s',
              hsl[1],
            );
            document.documentElement.style.setProperty(
              '--primary-color-l',
              hsl[2],
            );
          }
          if (getters.currentCollective.themeCostumization.secondaryColor) {
            document.documentElement.style.setProperty(
              '--secondary-color',
              getters.currentCollective.themeCostumization.secondaryColor,
            );
            let hslSecondary = getters.currentCollective.themeCostumization.secondaryColor;
            hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
            hslSecondary = hslSecondary.split(',');
            document.documentElement.style.setProperty(
              '--secondary-color-h',
              hslSecondary[0],
            );
            document.documentElement.style.setProperty(
              '--secondary-color-s',
              hslSecondary[1],
            );
            document.documentElement.style.setProperty(
              '--secondary-color-l',
              hslSecondary[2],
            );
          }
        }

        // En caso de ser un evento externo, se ha de redirigir a la web que toca.
        if (
          currentSpace.isEvent
          && currentSpace.joinURL != null
          && !currentSpace.isStaff
        ) {
          window.location.href = currentSpace.joinURL;
        }

        // if (currentSpaceCommunityMemberStatus != 3) {
        //     if (!inWelcome) {
        //       if (
        //         currentSpace.isEvent ||
        //         currentSpace.mainType?.toLowerCase() === "project"
        //       ) {
        //         window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        //           "{slug}",
        //           currentSpace.slug
        //         ).replace(
        //           "{subdomain}",
        //           currentSpace.subdomain || "app"
        //         )}/welcome`;
        //       } else {
        //         router.replace({
        //           name: "welcome",
        //           params: {
        //             communitySlug: currentSpace.slug,
        //             communityId: currentSpace.slug,
        //           },
        //         });
        //       }
        //     }
        // }

        if (Vue.$cookies.get('lastUrl')) {
          const url = Vue.$cookies.get('lastUrl');
          Vue.$cookies.keys().forEach((cookie) => (cookie === 'lastUrl' ? Vue.$cookies.remove(cookie) : ''));
          Vue.$cookies.remove('lastUrl', '/', `.${getDomain()}`);
          Vue.$cookies.remove('registeredTo', '/', `.${getDomain()}`);
          Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));
          Vue.$cookies.keys().forEach((cookie) => (cookie === 'community' ? Vue.$cookies.remove(cookie) : ''));

          window.location.replace(url);
        }
      } else {
        // ====================
        //  Todo funciona mal (No es usuario etc...)
        // ====================
        if (currentSpace.userNotInsideCommunity === true) {
          // console.log('Detecting user not inside community in collective.store');
          // console.log('currentSpace.canJoin', currentSpace.canJoin);
          // console.log('currentSpace.canRequest', currentSpace.canRequest);
          // console.log('currentSpace.message', currentSpace.message);
          // console.log('inWelcome', inWelcome);

          if (currentSpace.canJoin || currentSpace.canRequest || currentSpace.message) {
            //console.log('can join, can request or message');
            if (!inWelcome) {
              if (
                currentSpace.isEvent
                || currentSpace.mainType?.toLowerCase() === 'project'
              ) {
                window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
                  '{slug}',
                  currentSpace.slug,
                ).replace(
                  '{subdomain}',
                  currentSpace.subdomain || 'app',
                )}/welcome`;
              } else {
                router.replace({
                  name: 'welcome',
                  params: {
                    communitySlug: currentSpace.slug,
                    communityId: currentSpace.slug,
                  },
                });
              }
            }
          } else {
            return router.push({
              name: 'error',
              params: { error: 'unauthorized-user' },
            });
          }
        } else {
          return router.push({ 
            name: 'error', 
            params: { 
              error: '404' 
            } 
          });
        }
      }
    },  //end initCurrentCommunity

    async initCurrentBackoffice(
      { dispatch, commit, getters, rootGetters, state, rootState },
      { communitySlug, force = false },
    ) {
      commit('setBackofficeInitialized', null);
      if (
        getters.currentCollective
        && getters.currentCollective.slug === communitySlug
        && !force
      ) {
        return;
      }

      if (!communitySlug) {
        return;
      }

      // Primero conseguira la comunidad
      const isCurrentCollectiveInStore = communitySlug === getters.currentCollective?.slug;

      if(!getters.currentCollective?.slug || !isCurrentCollectiveInStore){
        await dispatch('getCommunity');               // --> getters.currentCollective
      }
      const currentSpace = getters.currentCollective;

      // Después comprueba si existe la comunidad. En caso de que no exista lleva a la pagina de 404.
      if (!currentSpace) {
        return router.push({ name: 'error', params: { error: '404' } });
      }

      //Después obtiene la comunidad main
      if(!getters.mainCollective?.slug){
        await dispatch('getMainCommunity');           // --> getters.mainCollective
      }
      const mainCommunity = getters.mainCollective;

      if(!getters.parentCollective?.slug){
        await dispatch('getParentCommunity');         // --> getters.parentCollective
      }
      const parentCommunity = getters.parentCollective;

      await dispatch('getCommunityCustomizations'); // --> ??
      
      commit('setBackofficeInitialized', currentSpace.slug);
    },

    async unsubscribeCommunity({ commit }, { communityKey, communitySlug }) {
      await $services[Service.BackendClient].post('unsubscribeCommunity', {
        communityKey,
        communitySlug,
      });
    },
    async putAddon({ commit, rootState }, { about }) {
      const community = rootState?.collective.collective?.slug;
      const requestData = {
        communitySlug: community,
        about,
      };
      try {
        const response = await $services[Service.BackendClient].put(
          'putAbout',
          {
            ...requestData,
          },
        );

        commit('setCurrentCollective', response.data);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async editCommunity(
      { commit, rootState },
      {
        headerData, images, isBgURL, settings, isLogoURL, forceUpdate,
      },
    ) {
      const community = rootState?.collective.collective?.slug
        || rootState?.collective?.parentCollective.slug;
      const requestData = {
        communitySlug: community,
        // TODO: only use communityKey, ask to BE
        ...(typeof headerData === 'object'
          && Object.entries(headerData).length > 0
          ? { header: { ...headerData } }
          : ''),
        ...settings,
        generalBanner: true,
      };
      let response;

      const deletes = {};
      if (images?.deleteLogo) {
        requestData.deleteLogo = true;
        deletes.deleteLogo = true;
      }

      if (images?.deleteBanner) {
        requestData.deleteBanner = true;
        deletes.deleteBanner = true;
      }

      if (images?.banner_url || images?.logo_url || images?.banner_url_mobile) {
        const bannerType = isBgURL ? 'bannerURL' : 'banner';
        const formData = new FormData();
        if (images.banner_url != null) {
          formData.append(bannerType, images.banner_url);
        }
        const bannerTypeMobile = 'bannerMobile';
        if (images.banner_url_mobile != null) {
          formData.append(bannerTypeMobile, images.banner_url_mobile);
        }
        if (images.logo_url != null) {
          const logoType = isLogoURL ? 'logoURL' : 'logo';
          formData.append(logoType, images.logo_url);
        }
        formData.append('data', JSON.stringify(requestData));
        response = await $services[Service.BackendClient].put(
          'communities',
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
        if (forceUpdate) {
          commit('setCurrentCollective', response.data);
        }
      } else {
        try {
          response = await $services[Service.BackendClient].put('communities', {
            communitySlug: community,
            ...deletes,
            ...settings,
            ...(typeof headerData === 'object'
              && Object.entries(headerData).length > 0
              ? { header: { ...headerData } }
              : ''),
          });
          if (forceUpdate) {
            commit('setCurrentCollective', response.data);
          }
        } catch (e) {
          commit('setError', e.response.data);
        }
      }
      response.data.isStaff = true;
      const communityType = 'setCurrentCollective';
      setInterval(() => {
        commit(communityType, response.data);
      }, 4000);
      return response.data;
    },
    async createCommunity({ commit, rootGetters }, { name, type }) {
      const response = await $services[Service.BackendClient].post(
        'communities',
        {
          name,
          ...type,
        },
      );
      return router.replace({
        name: 'my-plans',
        params: { community: response.data.slug },
      });
    },
    async removeCommunity({ commit, rootState }) {
      const communitySlug = rootState.collective.collective.slug;
      const response = await $services[Service.BackendClient].post(
        '/communities/delete',
        {
          ...{ communitySlug },
        },
      );
      return response;
    },
    async addCommunityCustomizations({ commit, rootGetters }, { owner }) {
      const response = await $services[Service.BackendClient].put(
        'communityCustomizations',
        {
          communityKey: rootGetters.currentCollective.key,
          owner,
        },
      );
      commit('setOwner', response.data);
    },
    async getCommunityCustomizationsOwner({ commit, rootGetters }) {
      const response = await $services[Service.BackendClient].get(
        'communityCustomizations',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
          },
        },
      );
      commit('setOwner', response.data?.community_customizations.owner);
    },
    async getCommunityCustomizations({ commit, rootGetters }) {
      const response = await $services[Service.BackendClient].get(
        'communityCustomizations',
        {
          params: {
            communityKey: rootGetters.currentCollective.key,
          },
        },
      );
      commit('setCustomization', response.data?.community_customizations);
    },
    async editFooter({ commit, rootState }, { body }) {
      const community = rootState.collective.collective.slug
        || rootState.collective.parentCollective.slug;
      const requestData = {
        communitySlug: community,
        footer: { body },
      };
      const response = await $services[Service.BackendClient].put(
        'communities',
        requestData,
      );
      return response.data;
    },
    async addSettings(
      { rootGetters },
      {
        links, emails, phones, timezone, showChatbot, customChatbot,
      },
    ) {
      await $services[Service.BackendClient].put('communitySettings', {
        communitySlug: rootGetters.currentCollective.slug,
        links,
        emails,
        phones,
        timezone,
        showChatbot,
        customChatbot,
      });
    },
    async addLocations({ rootGetters }, { locations }) {
      await $services[Service.BackendClient].put('communityLocations', {
        communitySlug: rootGetters.currentCollective.slug,
        locations,
      });
    },
    async addLanguages(
      { rootGetters },
      { availableLanguages, defaultLanguage },
    ) {
      await $services[Service.BackendClient].put('communityLanguages', {
        communitySlug: rootGetters.currentCollective.slug,
        availableLanguages,
        defaultLanguage,
      });
    },
    getLanguages({ rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('languages', {
            params: {
              communityKey: rootGetters.currentCollective.key,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch();
      });
    },
    getPrefixes({ rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('countries', {
            params: {
              communityKey: rootGetters.currentCollective.key,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch();
      });
    },
    getTimezones({ rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/timezones', {
            params: {
              communityKey: rootGetters.currentCollective.key,
              getAll: 1,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch();
      });
    },
  },
};
